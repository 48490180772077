import React from "react";

function FullscreenImage(){
    return (
        
        <div style={{paddingLeft:'10px',paddingRight:'10px'}}>
            <div class="bg-image img-fluid rounded" style={{backgroundImage: 'url("./images/homepage/i.jpg")'}} id="fullScreenImage">
            </div>
        </div>
    );

}

export default FullscreenImage;