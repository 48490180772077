import { useParams } from 'react-router-dom';
import Navbar from './Navbar';

function ShootName(){

    const { shootname } = useParams();
    const { images } = useParams();
    
    return(
        <div>
            <div class="col-12" id="bigLogo"></div>
            <div class="container-fluid" id="showNavbarLogoProxy" ></div>
            <Navbar/>
            shoot name coming soon {shootname} {images}

            <img src="..." class="rounded float-end" alt="..."></img>
        </div>
    )
}

export default ShootName;