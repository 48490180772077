
import React, { useEffect } from 'react';
import ContactUs from './ContactUs';

function Navbar(){

    useEffect(() => {
        const canvas = document.getElementById('showNavbarLogoProxy');
        const bigLogo = document.getElementById('bigLogo'); 
        const bigLogoRect = bigLogo.getBoundingClientRect();
        
        
        
        function isElementVisible(el) {
            
            const rect = el.getBoundingClientRect();
            //console.log(el.top-bodyRect.top);    
            //console.log(rect.top);    
            //console.log(rect.top-bodyRect.top)
            return (
                 
              rect.top >= bigLogoRect.top &&
              rect.left >= 0 
              //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
              //rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
          }

          function checkElementVisibility() {
            //alert(yourElement)
            if (isElementVisible(canvas)) {
              // Element is visible, do something
              //console.log('Element is visible');
              document.getElementById("navbarLogo").style.display = "none";
            } else {
                document.getElementById("navbarLogo").style.display = "inline";
              // Element is not visible, do something else
              //console.log('Element is not visible');
            }
          
          }
          


        window.addEventListener('scroll', checkElementVisibility);
        checkElementVisibility();

          }, []);
      

    return (
        <div>
        <nav class="navbar fixed-top .navbar">
            <div class="container">
                <div id="logoNavbar">
                    <h2 class="logo-font" id="navbarLogo">the bicycle tale</h2>
                </div>
                <a href="" data-bs-toggle="modal" class="text-decoration-none link-dark" data-bs-target="#contactModal">CONTACT US</a>
            </div>

        </nav>

        <div class="modal fade" id="contactModal" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5"><b>Contact Us</b></h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div class="modal-body">
                    
                    <ContactUs></ContactUs>

                    {/*<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdsn27IE39iD4VDEL4mCc1W3V1uMxn3cAQZk9gh5o79wAuReQ/viewform?embedded=true" width="100%" height="482" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>*/}
                    </div>
                </div>
                </div>
            </div>    
        </div>    
    );   
}
/*<nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid ">
            <div class="mx-auto">
                
            </div>       
            <div>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="#">Right</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav> */
export default Navbar;





