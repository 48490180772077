
import React from "react";

function TextBlocks(props){



    const section=props.section;

    if (section==="intro"){

    return  (
            <div class="row">
            <div class="col-12" id="bigLogo">
                <div class="parallax">
                    <h1 class="display-1 logo-font">the bicycle tale</h1>
                    <div class="logo-intro">
                    <p class="display-4" id="iv">Images & Videos</p>
                    <p class="h4">We are dedicated to capturing all of life's special moments.
                    With a keen eye for detail and a passion for storytelling, we pride ourselves on creating beautiful, timeless images.
                    We feel privileged to document memories that you will treasure for years to come.</p>
                    <br/>
                    
                </div>
                </div>
            </div>
            </div>
            
            /*<div class="row">
                <div class="col-12" id="bigLogo"><h1 class="display-1 logo-font">the bicycle tale</h1></div>
            </div>
            <div class="row">
                
                <div class="col-12 logo-intro">
                    <p>We are dedicated to capturing all of life's special moments.<br/>
                    With a keen eye for detail and a passion for storytelling, we pride ourselves on creating beautiful, timeless images.<br/>
                    We feel privileged to document memories that you will treasure for years to come.</p>
                </div>
            </div>*/
        /*<div class="container " >
            <div class="row">
                <div class="col-12 col-sm-7" id="bigLogo"><h1 class="display-1 logo-font">the bicycle tale</h1></div>
                <div class="col-12 col-sm-5 logo-intro">
                    <p>We are dedicated to capturing all of life's special moments.</p>
                    <p>With a keen eye for detail and a passion for storytelling, we pride ourselves on creating beautiful, timeless images.</p> 
                    <p>We feel privileged to document memories that you will treasure for years to come.</p>
                </div>
            </div>
        </div>*/
        
    )
    }else if (section==="subintro"){

        return(
            <div class="row" style={{paddingLeft:'50px',paddingRight:'10px',paddingTop:'250px',paddingBottom:'150px'}}>
            <div class="col-lg-6 col-sm-12 align-items-center" >
                    <p class="h5" justify-content-center>Creating images is an artful process, requiring deliberate effort and skill. Whether it involves seizing spontaneous moments to capture the perfect candid shot or skillfully eliciting ideal reactions with just the right words, our extensive experience has honed our ability to navigate the myriad aspects of a special day or an event, ensuring that no precious moment goes unnoticed.</p>
                </div>
                
                </div>
            
    )
    }
    ;
}
export default TextBlocks;