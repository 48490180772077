import React, { useRef, useState} from 'react';



function Cards(){
    

    
    const [indexConst, setIndexConst]=useState(0);
    const [title, setTitle]=useState('');
    const [totalImages, setTotalImages]=useState(0);
    const [perColImages,setPerColImages]=useState(0);

    
    let str='';
    let counter=0;
        const myElementRef = useRef(null);

        
        
    const indexValue = (value,txtValue,images) => {
        setIndexConst(value);
        setTitle(txtValue);
        setTotalImages(images);        
        setPerColImages(Math.round(images/3));
    };
    
        
        
        for (let i = 0; i < totalImages; i++) {
            counter++;
            if (counter===1){
                str=str+'<div class="col-12 col-sm-4">' 
                //str=str+'<div class="col-sm-6 col-lg-4 mb-4>'
                str=str+'<div class="card  border-0">'
            }else{
                str=str+'<div class="card border-0">'
            }    
    
            str=str+'<div class="card-body">'
            str=str+'<img src="./images/shoots/'+indexConst+'/'+(i+1)+'.jpg" class="img-fluid rounded" />'
            
            console.log('<img src={`./images/shoots/'+indexConst+'/'+i+'.jpg`} class="img-fluid rounded" />')
            str=str+'</div>'
            str=str+'</div>'
            
            if (counter===perColImages){
                counter=0;
                str=str+'</div>'
           }
        }
        
        if(str===''){
            str="<div></div>"
        }
    
    
    
        //useEffect(() => {
        //    const contentArray = [];
        //    const element = myElementRef.current;
        
        //    if (element) {
              // Render HTML content
        //      element.innerHTML = str;
        //    }
        //  }, []); 
        
        const generateContent = () => {
            // Logic to generate content based on the loop count
            //const contentArray = [];
             // contentArray.push(str);
            
            //return contentArray;

            
            const element = myElementRef.current;
        
            if (element) {
              // Render HTML content
              element.innerHTML = str;
            }
          };
        

    return  (
            <div class="row" style={{paddingBottom:'20px',border:'0px solid red',color:'white'}}>                  
            <div class="row row-cols-1 row-cols-md-5 g-5 mx-auto"> 
            <div class="col" >
                    <button class="btn border-0 shadow-none" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue(6,'Ruby\'s Christmas Shoot ',12)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                        
                            <img src="./images/homepage/rubyshristmas.jpg" class="card-img-top rounded" alt="..."  />
                            <div class="card-img-overlay">
                                <h3 class="card-title" ><b>Ruby & Rahul Christmas Shoot</b></h3>
                            </div>
                        </div>   
                        </button>
                    </div>

                    <div class="col" >
                    <button class="btn border-0 shadow-none" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue(1,'Kanika/Kunwar Maternity Shoot',5)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                        
                            <img src="./images/homepage/kk.jpg" class="card-img-top rounded" alt="..."  />
                            <div class="card-img-overlay">
                                <h3 class="card-title" ><b>Kanika/Kunwar Maternity Shoot</b></h3>
                            </div>
                        </div>   
                        </button>
                    </div>

                    <div class="col ">
                    <button class="btn border-0 shadow-none" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal"   onClick={() => indexValue(2,"Preeti's Babyshower",8)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                            <img src="./images/homepage/pr.jpg" class="card-img-top rounded" alt="..."/>
                            <div class="card-img-overlay">
                                <h3 class="card-title" ><b>Preeti's Babyshower</b></h3>
                            </div>
                        </div>
                        </button>    
                    </div>

                    <div class="col ">
                    <button class="btn border-0 shadow-none" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue(3,"Kiara's 4th Birthday Celebration",12)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                            <img src="./images/homepage/kr.jpg" class="card-img-top rounded" alt="..." />
                            <div class="card-img-overlay">
                                <h3 class="card-title"><b>Kiara's 4th Birthday Celebration</b></h3>
                            </div>
                        </div>
                    </button>        
                    </div>

                    <div class="col ">
                    <button class="btn border-0 shadow-none" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue(4,"Ruby's Babyshower", 7)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                            <img src="./images/homepage/rb.jpg" class="card-img-top rounded" alt="..."/>
                            <div class="card-img-overlay">
                                <h3 class="card-title"><b>Ruby's Babyshower</b></h3>
                            </div>
                        </div>    
                    </button>
                    </div>
                    <div class="col ">
                    <button class="btn border-0 shadow-none" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue(5,"Aadiv's 5th Birthday Celebrations", 13)}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                            <img src="./images/homepage/ad.jpg" class="card-img-top rounded" alt="..."/>
                            <div class="card-img-overlay">
                                <h3 class="card-title"><b>Aadiv's 5th Birthday Celebrations</b></h3>
                            </div>
                        </div>    
                    </button>    
                    </div>

                    <div class="col ">
                    <button class="btn border-0 shadow-none" href="#"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => indexValue('6')}>
                        <div class="card w-85 h-10 text-bg-dark mx-auto border-0">
                            <img src="./images/homepage/ar.jpg" class="card-img-top rounded" alt="..."/>
                            <div class="card-img-overlay">
                                <h3 class="card-title"><b>Aron's 2nd Birthday Celebrations</b></h3>
                            </div>
                        </div>
                    </button>        
                    </div>


                </div>
                
                <div className="container" >
                
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true" >
  
  <div class="modal-dialog modal-fullscreen" style={{padding:'20px'}}>
    <div class="modal-content rounded">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel"><b>{title}</b></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      {/*<div class="row mb-5" data-masonry='{"percentPosition": true }' id="masonary" ref={myElementRef}>*/}
      <div class="row mb-5" data-masonry='{"percentPosition": true }' id="masonary" ref={myElementRef}>
      {generateContent()}
      </div>
                </div>
                </div>
                </div>    
  </div>
</div>
</div>

                     
        
    );
}
export default Cards;