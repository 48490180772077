import React from "react";




function Carousel(){
    return (
  <div>    
  <div class="carousel slide carousel-fade rounded" data-bs-ride="carousel" id="carousel-home" data-bs-pause="false" data-bs-interval="1000">
  <div class="carousel-inner">
    <div class="carousel-item active" >
      <img src="./images/homecarousel/i1.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i2.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i3.jpg" alt="..." class="img-fluid rounded" />
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i4.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i5.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i6.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i7.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i8.jpg" alt="..." class="img-fluid rounded"/>
    </div>
    <div class="carousel-item" >
    <img src="./images/homecarousel/i9.jpg" alt="..." class="img-fluid rounded"/>
    </div>
  </div>
  <div class="row" id="carousal-snippet">
            <div class="col-lg-6 col-sm-12 carousal-snippet" ><p class="h4">Established: <br/>26 September 2023</p></div>
            <div class="col-lg-6 col-sm-12 carousal-snippet" ><p class="h4">Pedaling through stories we capture exquisite details that make your special occasions unforgettable.</p></div>
            </div>
</div>
</div>


    );
}

export default Carousel;