import './App.css';
import React from 'react';
import {Route, Routes } from 'react-router-dom';
import ShootName from './components/ShootName';
import Home from './components/Home';

function App() {
  return (
    <div className="App">  
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path='/events/:shootname/:images' element={<ShootName/>} />
      </Routes>
    
    


    </div>
    );
}

export default App;
