import TextBlocks from './TextBlocks';
import Navbar from './Navbar';
import Carousel from './Carousel';
import FullscreenImage from './FullscreenImage';
import Cards from './Cards';
import Footer from './Footer';
import React from 'react';


function Home(){
    return(
        <div>
        <div class="container">
        <Navbar/>
      </div>

      <div class="container">
            <TextBlocks section="intro"/>
      </div>
      <div style={{backgroundColor:"#f7f7f2",position: "relative"}}>
      <div class="container-fluid" id="showNavbarLogoProxy" >
          <Carousel/>
      </div>

      <div class="container-fluid">
        <FullscreenImage/>
      </div>

      <div class="container-fluid">
        <TextBlocks section="subintro"/>
      </div>

      <div class="container-fluid py-2">
        <Cards/>
      </div>
      <div class="container-fluid">
        <Footer />
        <br></br>
      </div>
      
    </div>
    </div>
    )
}

export default Home;